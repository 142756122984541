/* BottomSheet.css */
.bottom-sheet {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.2);
    z-index: 1000;
    transform: translateY(100%);
    transition: transform 0.3s ease-out;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centrer les éléments horizontalement */
    padding: 20px;
  }

  .bottom-sheet.open {
    transform: translateY(0);
  }
  
  .bottom-sheet-header {
    display: flex;
    flex-direction: column; /* Organiser les éléments en colonne */
    align-items: center; /* Centrer les éléments horizontalement */
    width: 100%;
  }
  
  .bottom-sheet-logo {
    height: 10em; /* Taille du logo réduite */
    margin-bottom: 10px; /* Espace entre le logo et les informations */
  }
  
  .bottom-sheet-info {
    display: flex;
    flex-direction: column; /* Empiler verticalement */
    align-items: center; /* Centrer les éléments horizontalement */
    width: 100%; /* S'assurer que l'info prend toute la largeur pour le centrage */
  }
  
  .bottom-sheet-info h2,
  .bottom-sheet-info p,
  .google-maps-btn {
    margin: 5px 0; /* Espacement vertical entre les éléments */
    text-align: center; /* Centrer le texte horizontalement */
  }
  
  .google-maps-btn {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #7cb615;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 50%; /* S'assurer que le bouton prend toute la largeur pour le centrage */
  }
  
  .google-maps-btn:hover {
    background-color: #6b9d0f; /* Une nuance légèrement plus foncée */
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 24px;
    line-height: 24px;
  }
  
  .close-btn:before {
    content: "\00D7"; /* Caractère Unicode pour une croix */
  }
  
  

  @media (max-width: 600px) {
    .bottom-sheet-logo {
        height: 5em; /* Taille du logo réduite */
        margin-bottom: 10px; /* Espace entre le logo et les informations */
      }
  }
  